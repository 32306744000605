<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="grid.md">
                        <span class="font-weight-bold">
                            {{item.date}} • <span class="gold--text">{{item.id}}</span>
                        </span>
                    </v-col>
                    <v-col cols="12" :md="grid.md" class="font-weight-medium">
                        <span class="gold lighten-1 white--text px-3 rounded">{{item.product}}</span>
                        <span class="tag-status white--text rounded px-3 ml-1" :class="state"> {{item.status}}</span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" no-gutters>
                    <v-col v-if="true" cols="12">
                        <v-row align="center" no-gutters>
                            <v-icon small class="gold--text pr-1">mdi-account-circle</v-icon>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">{{ item.customer }}</span>
                        </v-row>

                        <v-divider class="gold lighten-1"></v-divider>

                        <v-row align="center" no-gutters class="headline" style="font-size: 1.2em !important;">
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t(agreement_keys + 'target_price') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.target_price}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center">
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t(agreement_keys + 'payments') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.payments}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t(agreement_keys + 'monthly_payment') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.monthly_payment}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t(agreement_keys + 'grams') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.grams}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t(agreement_keys + 'coins') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{ g_isDefined(item.coins) }}</v-col>
                                </v-row>
                            </v-col>
                            <v-col v-bind="layout">
                                <v-row no-gutters align="center" >
                                    <v-col cols="12" class="caption font-weight-bold">{{ $t(agreement_keys + 'points') }}</v-col>
                                    <v-col cols="12" class="gold--text">{{item.points}}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-col>
                    <!--FOOTER-->
                    <v-col cols="12" class="pt-2">
                        <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                            <v-icon size="20" class="px-1">mdi-account-tie</v-icon>
                            <span class="caption">{{ item.seller }}</span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "PackageAgreementCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        grid: {sm: 6, md: 6, lg: 2},
        card: { cols: 6, xs: 6, sm: 4, md: 2, align: 'center', class: 'my-2'},
        agreement_keys: 'products_agreements.table_keys.'
    }),
    computed: {
        layout() {
            return this.card;
        }
    }
}
</script>